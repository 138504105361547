@import "_home.scss";

.internas{
	background-color:#000;
	color:#FFF;
	padding-top:78px;
	padding-bottom:56px;

	hr.hr-divider{
		border-top:9px solid #FFF;

		&.hr-divider--222{
			border-color:#222222;
		}

		&.peq{
			border-top:1px solid #FFF;
		}

	}

	.internas__max-content{
		margin-right:auto;
		margin-left:auto;

		&.max--840{
			max-width:840px;
		}

		&.max--882{
			max-width:882px;
		}

		&.max--922{
			max-width:922px;
		}

		&.max--970{
			max-width:970px;
		}

		&.max--1054{
			max-width:1054px;
		}

		&.max--1064{
			max-width:1064px;
		}

	}

	.internas__title{
		font-size:23px;
		margin-bottom:46px;
		line-height:1;
		padding-bottom:16px;
		font-family: 'montserratbold';
		border-bottom:11px solid #FFF;
	}

	&.internas--cadastro{

		.pessoaFisica{

			.fisica{
				display:block !important;
			}

			.juridico{
				display:none !important;
			}

		}

		.pessoaJuridica{

			.fisica{
				display:none !important;
			}

			.juridico{
				display:block !important;
			}

		}

	}

	&.internas--cadastro,
	&.internas--categorias{

		.internas__title{
			margin-bottom:22px;
		}

	}

	&.internas--fale-conosco{

		.internas__title{
			margin-bottom:26px;
		}

	}

	&.internas--carrinho{

		.internas__title{
			border-bottom:none;
			margin-bottom:0;
		}

		.row--internas{
			position:relative;
			margin-bottom:54px;

			&:before{
				content:'';
				display:block;
				position:absolute;
				top:100%;
				left:15px;
				height:11px;
				background-color:#FFF;
				width:calc(100% - 30px);
			}

		}

	}

	.finalizar-compra{
		padding-top:20px;
		border-bottom:8px solid #DDDDDD;
		padding-bottom:38px;
		max-width:632px;
		margin-right:auto;

		@include media-breakpoint-down(md){
			margin-left:auto;
		}

		.row{
			align-items:center;
			
			& > *{
				margin-bottom:10px;
			}

		}

		.finalizar-compra__title,
		.finalizar-compra__subtitle{
			line-height:1;
			letter-spacing:1px;
		}

		.finalizar-compra__title{
			font-size:16px;
			font-family: 'montserratbold';
			margin-bottom:54px;
		}

		.finalizar-compra__subtitle{
			font-size:18px;
			padding-left:2px;

			span{
				font-family: 'montserratbold';
			}

		}

		.finalizar-compra__figure{
			margin-bottom:10px;
		}

		& + .finalizar-compra{
			border:none;
			padding-top:46px;
		}

	}

	.resumo-pedido{
		max-width:300px;
		margin-left:auto;
		margin-right:auto;
		margin-bottom:30px;
		
		.resumo-pedido__header{
			background-color:#c23a3a;
			font-size:16px;
			font-family: 'montserratbold';
			padding-right:14px;
			padding-left:14px;
			padding-top:18px;
			padding-bottom:12px;
		}

		.resumo-pedido__content{
			background-color:#1d1d1d;
			padding:11px;

			.resumo-pedido__title{
				background-color:#ededed;
				color:#333;
				font-size:16px;
				font-family: 'montserratbold';
				padding:7px 16px;
				margin-bottom:12px;
			}

			.resumo-pedido__text{
				padding-left:22px;
				padding-right:22px;
				max-width:220px;
				font-size:13px;

			}

		}

	}

	.tab-finalizar-compra{

		*[data-before]{

			@include media-breakpoint-down(md){
				
				&:before{
					content:attr(data-before);
					display:block;
					padding:10px 15px;
					width:100%;
					color:#000;
					font-family: 'montserratbold';
				}

			}

			&.col-lg-4,
			&.col-lg-2{

				&:before{
					color:#FFF;
				}

			}

		}

		@include media-breakpoint-down(md){
			text-align:center;
		}

		.tab-finalizar-compra__header{
			font-size:12px;
			font-family: 'montserratsemibold';
			margin-bottom:4px;

			@include media-breakpoint-down(md){
				display:none;
			}

		}

		.row{
			width:100%;
		}

		.tab-finalizar-compra__figure{
			margin-bottom:0;

			@include media-breakpoint-up(lg){
				text-align:right;
			}

		}

		.tab-finalizar-compra__text{
			background-color:#ededed;
			color:#656565;
			padding:5px 10px;
			max-width:366px;
			margin-left:auto;
			margin-right:auto;
			display:flex;
			height:100%;
			align-items:center;
			justify-content:center;
			font-size:13px;

			@include media-breakpoint-up(lg){
				min-height:81px;
				padding:13px 18px;
			}


			&.text--maior{
				font-size:14px;
			}

		}

		.tab-finalizar-compra__content{
			
			@include media-breakpoint-up(lg){
				display:flex;
				flex-wrap:wrap;
			}

			@include media-breakpoint-down(md){
				margin-bottom:30px;
			}

			.col-lg-2{

				@include media-breakpoint-up(lg){
					word-break:break-all;
				}

			}

			& > *{
				margin-bottom:11px;
			}

		}		

	}

	.internas__endereco{
		margin-bottom:35px;
		font-weight:bold;
	}

	&.internas--produtos-detalhes{


		.card.card-add-carrinho{
			padding:18px 12px;
		}

		lazy-image{
			display:inline-block;
		}

		.internas-content{
			padding-top:35px;
		}

		.fotos-detail-prod{

			.h5{
				color:#000000;
				font-size:12px;
				margin-bottom:22px;
			}

		}

		.title-valor{
			font-size:35px;
			line-height:1;
			margin-bottom:0;

			.text-small{
				font-size:15px;
			}

		}

		.texto-parcela{
			font-size:15px;
		}

		.rating{
			color:#D6D6D6;
			font-size:14px;

			& > *{
				@include margin(0,1);
			}

			.fa-star{

				&.full{
					color:#EAA931;
					position:relative;

					&:after{
						content:'';
						display:inline-block;
						width:100%;
						height:100%;
						position:absolute;
						top:0;
						left:0;
						@extend .fas;
						color:#EAA931;
					}

				}

				&.half{
					position:relative;

					&:after{
						content:'\f089';
						display:inline-block;
						width:100%;
						height:100%;
						position:absolute;
						top:0;
						left:0;
						@extend .fas;
						color:#EAA931;
					}

				}

			}

			.fa{
				@include margin(0,2);

			}

			&.rating-diferente{

				.fas{
					color:#CBCBCB;
					@include margin(0,3);

					&.full{
						color:#F2CA31;
					}

					&.half{

						&:after{
							content:'\f089';
							display:inline-block;
							width:100%;
							height:100%;
							position:absolute;
							top:0;
							left:-2px;
							color:#F2CA31;
						}

					}

				}

			}

		}

		.hr-divider{
			border-color:#EDEDED;
			border-width:4px;
		}

		.divider-hr{
			border-top:4px solid #eeeeee;
		}

		.row-imagens{

			& > *{
				@include margin(0,29);
				transition:box-shadow 0.6s linear;

				img{
					transition:border 0.6s linear;
					border:3px solid transparent;
				}

				a{
					z-index:2;
					position:relative;
					display:block;

					&:before{
						content:'';
						display:block;
						width:100%;
						height:100%;
						position:absolute;
						top:0;
						left:0;
						background-color:#C23A3A;
						transition:transform 0.6s linear;
						transform:scale(0);
					}

					&.zoomGalleryActive{
						
						&:before{
							transform:scale(1);
						}

					}

				}

				@include hover-focus(){
					border-color:$theme;
				}

				&:hover{
					box-shadow:none;

					a:before{
						opacity:1;
						z-index:30;
					}

				}

			}

		}

		.media-legal{
			font-size: 40px;
			margin-bottom:15px;
			text-align:center;
			align-items:center;
			padding-bottom:0;
			border-bottom:1px solid #E3E3E3;

			.nota{
				color: #666;
			}

			.rating,
			.nota{
				line-height:1;
			}

		}

		.depoimento{
			margin-bottom:12px;
			padding-bottom:20px;
			border-bottom:1px solid #E3E3E3;
			color:#666;

			.autor{
			}

			&.depoimento-peq{
				border:none;
				padding-bottom:0;
				margin-bottom:0;
			}

			&.depoimento-grande{
				font-size:30px;

				.rating{
					margin-bottom:20px;
				}

			}

			&:last-child{
				border:none;
			}

		}

		.details-prod-text{
			font-size: 16px;
			color: #383838;

			.card-body-cep{
				color:#333333;
				font-size:12px;

				.form-control{
					@include place(#8F8F8A);
					font-size:13px;
				}

				.form-control,
				.input-group-addon .btn{
					border-color:#ccc;
				}

				.fa-truck{
					margin-right:5px;
					font-size:15px;
				}

				.cep-text{
					font-size:10px;
					color:#FFF;
					font-style:italic;
				}

			}

			.avaliacoes{
				font-size:10px;
				align-self:center;
				font-style:normal;
			}

			.card{

				.btn-theme.btn-expanded{
					font-size:18px;

					.fa{
						font-size:25px;
						margin-right:3px;
					}

				}

			}

			.cod{
				margin-bottom:18px;
				font-size:12px;
				font-style:italic;
				color:#FFF;
				padding-top:11px;
			}

			.bg-grey{
				background: #222222;
				color:#FFF;
			}

			.card{
				border-radius:0;
				border:none;

				.btn-redondo{

					.fa{
						margin-right:10px;
						font-size:26px;
					}

				}

				.card-body{

					& > .row{
						align-items:center;
						margin-bottom:6px;
					}

				}

				.form-control{
					border-color:#FFF;
					min-height:44px;
					padding-left:16px;
					border:none;
					border-radius:0;
					font-size:12px;
					@include sombra(#FFF);
					@include place(#8E8E89);
				}

				.input-group-addon{

					.btn{
						border-color:#cecece !important;
						border-left:none;
						font-size:13px;
						color:#1F1F1F;
					}

				}

				.btn-frete{
					color:#FFF;
					width:28px;
					height:28px;
					line-height:28px;
					text-align:center;
					font-size:10px;
					padding:0;
					border-radius:100%;
				}

			}

			.form-control{
				border-color: #FFF;
				font-size:12px;
				color:#8E8E89;

				@include placeholdIt{
					color:#8E8E89;
				}

			}

			.input-group{
				
				.input-group-addon{
					display:flex;
					margin-left:-2px;

					.btn{
						font-size:13px;
						border:none;
						border-radius: 0;
						@include button-variant(#FFF, #FFF);
						font-family: 'montserratbold';

						&:hover{
							border-color: #222;
							color:#1F1F1F;
						}
					}

				}

			}

			.btn{
				white-space:normal;

				&.btn-question{
					padding:0;
					width:24px;
					height:24px;
					display:flex;
					align-items:center;
					border-radius:100%;
					justify-content:center;

					@include media-breakpoint-down(md){
						margin-left:auto;
						margin-right:auto;
					}

				}


				.fas{
					vertical-align:bottom;
				}

			}

			.card-preco{
				margin-bottom: 10px;
				display: flex;
				flex-wrap: wrap;
				text-align: center;
				font-size:14px;
				color:#FFF;
				padding:10px 15px;

				.boleto{
					font-size: 17px;
				}

				.special{
					font-size:14px;
					
					span{
						font-size: 20px;
					}

				}

				.cond{
					font-size:12px;
				}

				@include media-breakpoint-up(sm){
					flex-direction: row;
					align-items: center;

					.boleto{
						position:relative;
					}
				}

				& > *{
					padding: 5px 15px;

					&.preco-borda{
						position:relative;

						&:before{
							
							@include media-breakpoint-up(lg){
								content:'';
								display:block;
								position:absolute;
								right:40px;
								top:50%;
								width:3px;
								height:calc(100% - 20px);
								transform:translateY(-50%);
								background-color:#d1d0d0;
							}

						}

					}

					@include media-breakpoint-up(lg){
						width: 50%;
						text-align:left;
					}

				}
			}

			.card-body-adicionar{

				.btn{
					padding:6px 12px;
					font-size:18px;

				}

			}

			.compartilhar{

				.h5{
					font-size:12px;
					margin-bottom:0;
				}

			}
		}

		.avaliar{

			button,
			h2,
			.radio-custom{
			}

			h2 {
				color:#666;
				font-size: 20px;
				margin-bottom: 10px;
			}

			.subtitle{
				font-size:15px;
				color:#666;
				margin-bottom:15px;
			}

			.form-group{

				.subtitle{
					margin-bottom:10px;
				}

				.form-control{
					resize:none;
					background-color:#eeeeee;
					@include sombra(#EEE);
					border:none;
				}

			}

			.radio-custom{
				font-size:15px;
				color:#666;

			}

		}

		.media-legal .badge,
		.depoimento .badge{
			display:inline-block;
		}

		.modal-saber-cep{


			.modal-content{
				padding:20px;
			}

		}

		.form-cadastro-modal{
			padding:20px;

			.form-group,
			.input-group{

			}

		}

		.avaliacao--diferente{

			.avaliacao-diferente__title{
				color:#656565;
			}

			.rating{
				color:#656565;
			}

		}

		.depoimento--resultado{
			border-top:1px solid #FFF;
			padding-top:30px;
		}

	}

}