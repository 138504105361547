.radio-custom{
	font-size:13px;

	& + .radio-custom{
		
		@include media-breakpoint-up(sm){
			margin-left:15px;
		}

	}

	.nav-link{

		&.active{
			background-color:transparent;
		}

	}

	& > label{
		display:inline-block !important;
		position:relative !important;
		width:100% !important;
		height:100% !important;
	}

	span{
		position:relative;
		display:inline-block;
		width:1.5em;
		height:1.5em;
		border-radius:100%;
		border:5px solid transparent;
		vertical-align:bottom;
		background-color:transparent;
		margin-right:0.6em;
		transition:all 0.6s linear;
		font-size:12px;

		&:before{
			content:'';
			display:block;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			border-radius:100%;
			transform:scale(1);
			background-color:transparent;
			border:2px solid transparent;
			transition:all 0.6s linear;
		}

	}

	input{
		position:fixed;
		left:-200vw;

		&:checked ~ label > .circle-legal:before{
			transform:scale(1);
		}

	}

	&.radio-bolinha-centro{

		span{
			border:2px solid #000;

			&:before{
				border:3px solid #FFF;
			}

		}

		input:checked ~ label > .circle-legal{
			border-color:#000;

			&:before{
				background-color:#000;
				border-color:#FFF;
			}

		}

	}

	&.radio-custom-padrao{
		color:#666;
		font-size:13px;
		font-family: 'baloobhai-regular';

		.circle-legal{
			background-color:#DDDDDD;
			font-size:12px;
			vertical-align:sub;
			border:2px solid transparent;
		}

		input{

			&:checked ~ label > .circle-legal{
				border-color:#C43A3A;
				background-color:#C43A3A;

				&:before{
					background-color:#C43A3A;
				}

			}

		}

	}

	&.radio-custom-diferente{
		color:#FFF;
		font-size:15px;

		.circle-legal{
			background-color:#666666;
			font-size:12px;
			vertical-align:sub;
			border:2px solid #666666;
		}

		input{

			&:checked ~ label > .circle-legal{
				border-color:#BE3A36;
				background-color:#BE3A36;

				&:before{
					
				}

			}

		}

	}

	&.radio-custom-categorias{

		label{
			color:#7D8083;
			transition:color 0.6s linear;
		}

		span{
			font-size:8px;
			vertical-align:sub;
			border:2px solid #000;
			min-width:16px;
			min-height:16px;
			border-radius:4px;

			&:before{
				content:'';
				font-size:10px;
				font-family:'Font Awesome 5 Free';
				font-weight:900;
				top:calc(50% - 7px);
				left:calc(50% - 4px);
				color:#D12B35;
				border-radius:0;
				border:none;
				opacity:0;
			}

		}

		input{

			&:checked ~ label{
				color:#000;

				.circle-legal{
					border-color:#D12B35;

					&:before{
						opacity:1;
					}

				}

			}

		}

	}

}

.nav-link.active{

	input{

		& ~ label > .circle-legal{
			background-color:$theme;

			&:before{
				transform:scale(1);
			}

		}

	}

}