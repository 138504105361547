.btn{

	&.btn--responsivo{
		box-shadow:none;

		.bars{
			width:32px;
			height:5px;
			display:inline-block;
			position:relative;
			background-color:#FFF;

			&,
			&:before,
			&:after{
				transition:all 0.6s linear;
			}

			@include beforeAfter{
				content:"";
				display:block;
				width:100%;
				height:100%;
				background-color:inherit;
				position:absolute;
				left:0;
			}

			&:before{
				top:calc(100% + 4px);
			}

			&:after{
				bottom:calc(100% + 4px);
			}

		}

	}

	&.btn--pill{
		border-radius: 2rem;
	}

	&.btn--padrao,
	&.btn--padrao-2,
	&.btn--padrao-3,
	&.btn--padrao-big{
		font-family: 'montserratbold';
	}

	&.btn--padrao,
	&.btn--padrao-2{
		border-radius:0;
		font-size:13px;
	}

	&.btn--padrao{
		min-width:203px;
		padding:8px 12px;
	}

	&.btn--padrao-2{
		min-width:220px;
		padding:16px 24px;
	}

	&.btn--padrao-3{
		font-size:12px;
		min-width:73px;
	}

	&.btn--padrao-big{
		border-radius:0;
		min-width:274px;
		padding:30px 20px;
	}

	&.btn--padrao-4{
		font-size:12px;
		font-family: 'montserratbold';
		min-width:126px;
		border-radius:6px;
		padding:12px 12px;
	}

	&.btn--black{
		@include button-variant(#000,#000);
		color:#FFF;
	}

	&.btn--FFF{
		@include button-variant(#FFF,#FFF);
		color:#000;
	}

	&.btn--222{
		@include button-variant(#222,#222);
		color:#FFF;
	}

	&.btn--outline-FFF{
		@include button-outline-variant(#FFF,#FFF);
		color:#FFF;

		&:hover{
			color:#000 !important;
		}

	}

	&.btn--343434{
		@include button-variant(#343434,#343434);
		color:#FFF;
	}

	&.btn--block{
		min-width:auto;
		max-width:100%;
		display:block;
		width:100%;
	}

	&.btn--radius-0{
		border-radius:0px !important;
	}

	&.btn--radius-4{
		border-radius:4px !important;
	}

	&.btn--min-width-198{
		min-width:198px;
	}

}