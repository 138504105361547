select{
	background-image:url('../images/bg-select-custom.png');
	background-repeat:no-repeat;
	background-position:calc(100% - 15px) calc(50% + 4px);
	-webkit-appearance:none;

}

.form-topo{
	width:100%;
	margin-right:auto;
	margin-left:auto;

	@include media-breakpoint-down(md){
		max-width:200px;
	}

	@include media-breakpoint-up(lg){
		margin-left:12px;
		max-width:138px;
	}

	form{

		.input-group,
		.form-group{
			position:relative;

			.form-control{
				font-size:12px;
				font-family: 'montserratbold';
				@include place(#000);
				padding:0;
				height:auto;
				min-height:20px;
				padding-left:10px;
				border-radius:0;
				border:none;
				box-shadow:none;
				background-color:#FFF;

				@include media-breakpoint-down(md){
					min-height:36px;
				}

			}

			.btn{
				padding:0;
				line-height:1;
				border:none;
				z-index:5;
			}

			.input-group-prepend{
				margin:0;
				background-color:#FFF;
				padding-right:7px;
				padding-bottom:2px;
			}

		}

	}

}

.form-sem-label{

	form{

		.input-group,
		.form-group{

			label:not(.label-on){
				@include sr-only();
			}

		}

	}

}

.form-internas{

	form{

		.input-group,
		.form-group{
			margin-bottom:24px;

			.form-control{
				min-height:56px;
				background-color:#FFF;
				font-family: 'montserratbold';
				border-radius:0;
				@include place(#000);
				font-size:14px;
				padding-left:14px;
				@include sombra(#FFF);
			}

			textarea.form-control{
				padding-top:20px;
				resize:none;
			}

		}

	}

}

.form-newsletter{

	form{

		.input-group,
		.form-group{

			.form-control{
				border-radius:30px 0 0 30px;
				min-height:48px;
				@include sombra(#FFF);
			}

			.input-group-prepend{

				.btn{
					border-radius:0 30px 30px 0;
				}

			}

		}

	}

}

.form-contato{

	form{

		.input-group,
		.form-group{
			margin-bottom:30px;

			label{
				font-size:14px;
				margin-bottom:6px;
				font-weight:bold;
			}

			.form-control{
				border-radius:0;
				min-height:42px;
				font-size:13px;
				@include place(#666);
				padding-left:24px;
				background-color:#EEEEEE;
				@include sombra(#EEEEEE);
			}

		}

		fieldset{
			max-width:886px;
			margin-right:auto;
			margin-left:auto;
		}

	}

}

.form-mb-13{

	form{

		.input-group,
		.form-group{
			margin-bottom:13px;
		}

	}

}

.form-form-control-text-b9b9b9{

	form{

		.input-group,
		.form-group{
			
			.form-control{
				@include place(#B9B9B9);
			}

		}

	}

}

.form-produtos-detalhes{

	form{

		.form-group,
		.input-group{
			margin-bottom:20px;
			
			.form-control{
				min-height:32px;
				@include place(#000);
				@include sombra(#EEEEEE);
				font-size:13px;
				padding-left:12px;
				background-color:#EEEEEE;
				border-color:#EEEEEE;
				border-radius:6px;
			}

			textarea.form-control{
				resize:none;
				padding-top:16px;
				border-radius:4px;
			}

		}

	}

}