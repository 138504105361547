.internas--meus-pedidos-detalhes{

	.pedido-realizado{
		flex-grow:1;

		.tab-custom{
			background-color:#EDEDED;
			padding:5px 16px 5px 32px;
			position:relative;
			margin-bottom:10px;
			z-index:2;
			text-align:center;
			color:#1F1F1F;
			font-size:12px;
			font-family: 'montserratbold';

			.fas{
				font-size:17px;
			}

			@include beforeAfter{
				content:'';
				display:block;
				position:absolute;
				border:12px solid transparent;
				z-index:-2;
				height:100%;
			}

			&:before{
				left:100%;
				top:0;
			}

			&:after{
				border-left-color:#EDEDED;
				left:0;
				top:0;
			}

			&.active{
				background-color:#222;
				color:#fff;

				&:before{
					left:calc(100% + 0);
					border-left-color:#222 !important;
				}

			}

		}

		& + .pedido-realizado{

			.tab-custom{

				&:before{
					border-left-color:#EDEDED;
				}

			}

		}

		&:first-child{

			.tab-custom{

				&:after{
					border-left-color:transparent;
				}

			}

		}

		&:last-child{

			.tab-custom{

				&:before{
					border-left-color:transparent;
				}

			}

		}

	}

	.bg-gray{
		background-color:#ededed;
		color:#1f1f1f;
		padding:25px 15px 18px 15px;
		line-height:1.3;

		.calendario{
			font-size:16px;
		}

	}

}

.produto-table-separado{
	margin-bottom:15px;
	color:#1f1f1f;

	& > *{
		background-color:#EEEEEE;
		padding:23px 15px 20px 15px;
		flex-grow:1;
		line-height:1.3;

		.texto{
			font-size:16px;
		}

	}

	.title{
		font-size:14px;
		color:#1f1f1f;
		font-family: 'montserratbold';
	}

	.produto-head{

		@include media-breakpoint-up(lg){
			margin-right:10px;
			max-width:255px;
		}

	}

	.produto-body{
		
		@include media-breakpoint-up(lg){
			display:flex;
			max-width:440px;
			flex-wrap:wrap;
			justify-content:space-between;
			padding-right:80px;
			padding-left:56px;
			flex-grow:1;
		}

		.produto-body-boxes{
			flex-grow:1;

			@include media-breakpoint-up(lg){
				display:flex;
				flex-wrap:wrap;
				max-width:295px;
				margin-right:auto;
				margin-left:auto;
				justify-content:space-between;
			}

		}

	}

	.produto-footer{
		background-color:#222222;
		color:#FFF;
		font-size:14px;
		font-family: 'montserratbold';

		@include media-breakpoint-up(lg){
			max-width:218px;
		}

		.title{
			color:#FFF;
		}

	}

	@include media-breakpoint-up(lg){
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
	}

}