.tabs-produto{
	margin-bottom:2px;
	font-size:14px;
	color:#FFF;
	padding-top:12px;

	@include media-breakpoint-up(lg){
		display:flex;
		flex-direction:column;
	}

	.tab-title{
		font-size:20px;
		margin-bottom:8px;
		color:#FFF;
	}

	.nav-tab-prod{
		margin-bottom:0;
		display: flex;
		flex-wrap:wrap;
		list-style:none;
		padding-left:0;

		a{
			display: block;
			padding:5px 20px;
			background: #EDEDED;
			color:#222;
			font-size:13px;
			min-width:122px;

			&:hover{
				background: darken(#EEE,10%);
				text-decoration: none;
			}
		}

		.active a,
		.active a:hover{
			background: #343434;
			color:#FFF;
			cursor: default;
		}
	}


	.tab-produto{
		padding: 12px 25px;
		padding-left:0;
		padding-top:20px;
		display: none;
		color:#FFF;
		height:100%;
		background-color:#000;

		&.active {
			display: block;
			animation: fadeIn 0.3s linear;
		}
	}

}

.form-produtos-detalhes{

	form{


		.form-group,
		.input-group{

			.form-control{
				
			}

		}

	}

}