.paginacao{
	display: flex;
	flex-wrap: wrap;

	&.paginacao__start{
		justify-content: flex-start;
	}

	&.paginacao__center{
		justify-content: center;
	}

	&.paginacao__end{
		justify-content: flex-end;
	}

	&.paginacao__between{
		justify-content: space-between;
	}

	.page__item {
		display: inline-block;
		padding: 2px 4px;
		border: 1px solid #DDD;
		color:#666;
		background-color:#FFF;
		min-width:30px;
		text-align:center;
		font-size:11px;
		display:inline-flex;
		align-items:center;
		justify-content:center;
		min-height:30px;

		.fa-chevron-left{
			@extend .fa-angle-double-left;
		}

		.fa-chevron-right{
			@extend .fa-angle-double-right;
		}

		&:first-child{
			border-radius:4px 0 0 4px;
		}

		&:last-child{
			border-radius:0 4px 4px 0;
		}

	}

	a.page__item:hover{
		background-color:lighten(#000,20%);
		color:#FFF;
	}

	.page__item--active{
		background-color:#000;
		color:#FFF;	
	}
}
