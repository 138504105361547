@font-face {
		font-family: 'baloobhai-regular';
		src: url('../fonts/baloobhai-regular/baloobhai-regular.eot');
		src: url('../fonts/baloobhai-regular/baloobhai-regular.eot?#iefix') format('embedded-opentype'),
		 	 url('../fonts/baloobhai-regular/baloobhai-regular-webfont.woff2') format('woff2'),
 		 	 url('../fonts/baloobhai-regular/baloobhai-regular.woff') format('woff'),
		 	 url('../fonts/baloobhai-regular/baloobhai-regular.ttf') format('truetype'),
		 	 url('../fonts/baloobhai-regular/baloobhai-regular.svg#baloobhai-regular') format('svg');
		font-weight: normal;
		font-style: normal;
}

@font-face {
    font-family: 'montserratblack';
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot');
    src: url('#{$fonts}/montserrat-black/montserrat-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-black/montserrat-black-webfont.svg#montserratblack') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'montserratbold';
    src: url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.eot');
    src: url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-bold/montserrat-bold-webfont.svg#montserratbold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'montserratextrabold';
    src: url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.eot');
    src: url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat-extra-bold/montserrat-extra-bold-webfont.svg#montserratextrabold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'montserratregular';
    src: url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.eot');
    src: url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat_regular/montserrat-regular-webfont.svg#montserratregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'montserratsemibold';
    font-display: swap;
    src: url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.eot');
    src: url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.woff') format('woff'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/montserrat_semi_bold/montserrat-semi-bold-webfont.svg#montserratsemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}