@import "_fontfaces";

html{
	scroll-behavior:smooth;
}

body{
	background-color:#000;
	color:#666666;
	font-size:14px;
	font-family: 'montserratregular';

	@include media-breakpoint-down(md){
		padding-top: 0 !important;
	}

}

@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

#app{
	position:relative;
	overflow:hidden;
}

.text--white{
	color:#FFF !important;
}

.text--C23A3A{
	color:#C23A3A !important;
}

.slick-slider{
	text-align:center;

	.slick-track{
		
		@include media-breakpoint-up(sm){
			display:flex;
			flex-wrap:wrap;
			align-items:flex-end;
		}

	}

}

.logo{
	margin-bottom:0;
}

.internas img{
	@include img-fluid();
}

a,
.btn{
	transition:all 0.6s linear;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		color:inherit;
		text-decoration:none;
	}

}

*[data-animate]{
	opacity:0;
}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

lazy-image{
	background-color:transparent;
}

.slick-slider{

	img{
		display:inline-block !important;
	}

}

.carousel{
	text-align:center;

	.carousel-item{
		position: relative;
		z-index:200;
	}

}

:focus{
	outline:none !important;
}

picture{
	display:inline-block;
}

.font--italic{
	font-style:italic;
}

// concerta erro meu (João) de sprites em outros projetos
.icon{

	@each $icon,$value in $icons {
		&.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			background-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}

.letter-spaceing--3{
	letter-spacing:-3px;
}

.font--mnt-bld{
	font-family: 'montserratbold' !important;
}

.font--mnt-ex-bld{
	font-family: 'montserratextrabold' !important;
}

.font--mnt-blk{
	font-family: 'montserratblack' !important;
}

.font--mnt-reg{
	font-family: 'montserratregular' !important;
}

.font--baloo-reg{
	font-family: 'baloobhai-regular' !important;
}

.font--mnt-semi-bld{
	font-family: 'montserratsemibold' !important;
}

.font--bold{
	font-weight:bold !important;
}

.font--fnt-11{
	font-size:11px !important;
}

.font--fnt-12{
	font-size:12px !important;
}

.font--fnt-13{
	font-size:13px !important;
}

.font--fnt-14{
	font-size:14px !important;
}

.font--fnt-15{
	font-size:15px !important;
}

.font--fnt-17{
	font-size:17px !important;
}

.font--fnt-18{
	font-size:18px !important;
}

.font--fnt-20{
	font-size:20px !important;
}

.font--fnt-23{
	font-size:23px !important;
}

.font--fnt-24{
	font-size:24px !important;
}

.font--fnt-27{
	font-size:27px !important;
}

.font--fnt-40{
	font-size:40px !important;

	@include media-breakpoint-down(md){
		font-size:30px !important;
	}

}

.font--fnt-50{
	font-size:50px !important;

	@include media-breakpoint-down(md){
		font-size:30px !important;
	}

}

.video-incrivel{
	cursor:pointer;
	background-repeat:no-repeat;
	background-size:cover;
	background-position:center center;
	position:relative;

	&:after{
		content:'';
		display:block;
		position:absolute;
		top:calc(50% - 27px);
		left:calc(50% - 27px);
		z-index:20;
		@extend .icon;
		@extend .icon-play;

		@include media-breakpoint-up(lg){
			transition:transform 0.6s linear;
			transform:scale(0);
		}

	}

	&:hover{

		&:after{
			transform:scale(1);
		}

	}

	&.ativado{

		&:after{
			animation:fadeOutDown 0.6s linear forwards;
		}

	}

}

[data-desligador="true"]{
	display:none;
	background-color:rgba(#000,0.6);
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:700;
	cursor:pointer;
}

@keyframes explodeMidia{

	0%{
		opacity:1;
		transform:scale(1);
	}

	100%{
		opacity:0;
		transform:scale(18);
	}

}

@keyframes explodeMidiaMenor{

	0%{
		opacity:1;
		transform:scale(1);
	}

	100%{
		opacity:0;
		transform:scale(9);
	}

}

@keyframes scrollBanner{


	0%{
		transform:translateY(0);
	}

	100%{
		transform:translateY(10px);
	}

}

body{
	transition:padding-left 0.6s linear;

	&.menu-active{

		.btn--responsivo{

			.bars{
				transform:rotate(45deg);

				@include beforeAfter{
					transform:rotate(-90deg);					
				}

				&:before{
					top:0;
				}

				&:after{
					bottom:0;
				}

			}

		}

		.nav-content{
			left:0;
		}

	}

}