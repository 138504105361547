.topo{
	color:#FFF;

	@include media-breakpoint-up(lg){
		padding-top:11px;
	}

	.nav-content{
		font-family: 'montserratbold';

		@include media-breakpoint-down(md){
			position:fixed;
			top:0;
			left:-230px;
			width:230px;
			height:100%;
			overflow:auto;
			border-right:1px solid #FFF;
			z-index:1000;
			background-color:#000;
			color:#FFF;
			transition:left 0.6s linear;
		}

		@include media-breakpoint-up(lg){
			@include make-container();
			@include make-container-max-widths();
			display:flex;
			align-items:center;
			justify-content:space-between;
		}

		.logo{

			@include media-breakpoint-down(md){
				padding:30px;
			}

			@include media-breakpoint-up(lg){
				margin-right:16px;
				padding-top:2px;
			}

		}

		.parte-info{
			display:flex;

			@include media-breakpoint-down(md){
				flex-direction:column;
			}

			@include media-breakpoint-up(lg){
				align-items:center;
				justify-content:space-between;
				flex-grow:1;
			}

			.media-custom{
				font-size:12px;
				align-items:center;

				.media-custom__img{

					@include media-breakpoint-up(lg){
						align-items:center;
						display:flex;						
					}

				}

			}

			.form-topo{

				@include media-breakpoint-down(md){
					order:1;
					margin-bottom:25px;
				}

			}

			.menu-topo{
				list-style-type:none;
				margin-bottom:0;
				padding-left:0;
				font-size:13px;
				line-height:1;

				@include media-breakpoint-down(md){
					order:2;
				}

				@include media-breakpoint-up(lg){
					display:flex;
					align-items:center;
					flex-grow:1;
					min-width:440px;
				}

				li{

					a{

						@include media-breakpoint-down(md){
							display:block;
							padding:12px 15px;
						}

					}

					@include media-breakpoint-up(lg){

						& + li{
							margin-left:32px;
						}

					}

					&.li--diferente{
						font-size:12px;
					}

					&.dropdown{

						& > a{

							&:after{
								content:'';
								display:inline-block;
								margin-left:3px;
								vertical-align:middle;
								font-family:'Font Awesome 5 Free';
								font-weight:900;
								transform:translateY(-2px);
							}

						}

						.dropdown-menu{
							float:none;
							min-width:128px;
							margin:0;
							font-size:13px;
							font-family: 'montserratbold';
							text-align:center;
							border-radius:0;
							padding:5px 0;

							@include media-breakpoint-down(md){
								position:relative;
								display:block;
							}

							@include media-breakpoint-up(lg){
								// overflow:auto;
								// min-height:100px;
								// max-height:calc(100vh - 80px);
							}

							li{
								margin:0;

								a{
									display:block;
									padding:12px 15px;
									
									@include media-breakpoint-up(lg){
										padding:9px 12px;
									}

								}

								& + li{
									position:relative;

									&:after{
										content:'';
										display:block;
										position:absolute;
										top:-2px;
										right:0;
										background-color:#000;
										height:1px;
										width:100%;

										@include media-breakpoint-up(lg){
											width:calc(100% - 29px);
										}

									}

								}

								&:hover  > a,
								&.active > a{
									background-color:#DEDEDE;
								}

							}

							.dropdown-menu{
								background-color:#DEDEDE;

								@include media-breakpoint-up(lg){
									left:100%;
									top:0;
								}

								li{

									&.active > a,
									&:hover  > a{
										background-color:darken(#DEDEDE,5%);
									}

								}

							}

						}

						&:hover{

							@include media-breakpoint-up(lg){

								& > .dropdown-menu{
									display:block;
								}

							}

						}

					}


				}

			}

			.media-custom{

				&.media-custom--carrinho{

					@include media-breakpoint-down(md){
						display:none !important;
					}

					@include media-breakpoint-up(lg){
						margin-left:8px;
					}

					.number{
						font-size:13px;
						min-width:(18 / 13) * 1em;
						height:(18 / 13) * 1em;
						line-height:(18 / 13) * 1em;
						display:inline-flex;
						align-items:center;
						justify-content:center;
						text-align:center;
						background-color:#FFF;
						color:#000;
						border-radius:4px;
						margin-left:11px;
						padding-top:4px;
					}

				}

			}

		}

	}

	&.topo--index{
		
		@include media-breakpoint-up(lg){
			position:absolute;
			top:0;
			left:0;
			width:100%;
			z-index:400;
		}

		.nav-content{

			@include media-breakpoint-up(lg){
				align-items:flex-start;
			}

		}

	}

	&.topo--internas{
		background-color:#000;
		color:#FFF;

		.nav-content{

			.logo{

				@include media-breakpoint-up(lg){
					margin-right:60px;
					max-width:344px;
				}

			}

			.parte-info{

				.menu-topo{
					
					@include media-breakpoint-up(lg){
						order:1;
					}

					li{

						& + li{

							@include media-breakpoint-up(lg){
								margin-left:28px;
							}

						}

					}

				}

				& > a{

					@include media-breakpoint-up(lg){
						order:3;	
					}

				}

				.form-topo{
					
					@include media-breakpoint-up(lg){
						order:2;	
					}

				}

			}

		}

	}

	&.fx{

		@include media-breakpoint-up(lg){
			position:fixed;
			top:0;
			left:0;
			z-index:500;
			background-color:#000;
			width:100%;
			animation:fadeInDown 0.6s linear;
			box-shadow:0 0 8px rgba(#000,0.5);

			.logo{
				max-width:260px !important;
			}

		}

		.mbl-controls{
			position:fixed;
			top:0;
			left:0;
			z-index:500;
			width:100%;
			background-color:#000;
			animation:fadeInDown 0.6s linear;
			box-shadow:0 0 8px rgba(#000,0.5);
		}

	}

}