.card-produtos{
	margin-bottom:24px;

	.card-produtos__figure{
		min-height:286px;
		background-color:#FFF;
		display:flex;
		align-items:center;
		justify-content:center;
		margin-bottom:12px;
		position:relative;
		max-width:254px;
		margin-left:auto;
		margin-right:auto;

		&:before{
			content:'';
			display:block;
			position:absolute;
			background-color:rgba(#FFF,0.5);
			width:100%;
			height:100%;
			z-index:2;
			opacity:0;
			transition:opacity 0.6s linear;
		}

		.card-produtos__controls,
		.card-produtos__number{
			position:absolute;
			z-index:6;
		}

		.card-produtos__controls{
			bottom:0;
			left:0;
			width:100%;
			text-align:center;
			padding-bottom:19px;

			@include media-breakpoint-up(lg){
				opacity:0;
				transition:all 0.6s linear;
			}

			.fas{
				margin-right:6px;
				font-size:19px;
				vertical-align:middle;
				transform:translateY(-2px);
			}

		}

		.card-produtos__number{
			font-size:14px;
			width:(56 / 14) * 1em;
			height:(56 / 14) * 1em;
			line-height:(56 / 14) * 1em;
			text-align:center;
			display:inline-flex;
			align-items:center;
			justify-content:center;
			background-color:#000;
			color:#FFF;
			right:11px;
			top:9px;
			font-family: 'montserratbold';
			border-radius:100%;
		}

	}

	.card-produtos__content{
		font-family: 'montserratbold';

		.card-produtos__title{
			font-size:14px;
		}

		.card-produtos__valor{
			font-size:13px;
		}

	}

	&:hover{

		.card-produtos__figure{

			&:before{
				opacity:1;
			}

			.card-produtos__controls{
				opacity:1;
			}

		}

	}

}

.card-siga-nos{

	.card-siga-nos__figure{
		margin-bottom:0;
		text-align:center;

		@include media-breakpoint-up(lg){
			transition:filter 0.6s linear;
			filter:grayscale(100%);
		}

	}

	&:hover{

		.card-siga-nos__figure{

			@include media-breakpoint-up(lg){
				filter:grayscale(0%);
			}

		}

	}

}