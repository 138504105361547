.banner{

	.carousel-item{
		margin-bottom:0;
	}

	.banner__controls{
		position:absolute;
		top:0;
		left:0;
		height:100%;
		width:100%;
		padding-bottom:26px;

		.banner__clearfix{
			@include make-container;
			@include make-container-max-widths();
			width:100%;
			height:100%;
			position:relative;
		}

	}

	.banner__scroll{
		position:absolute;
		left:-18px;
		bottom:0;
		width:12px;
		height:93px;
		background-repeat:no-repeat;
		background-position:center center;
		z-index:300;
		animation:scrollBanner 2s alternate infinite;
	}

	.carousel-indicators{
		margin-bottom:26px;
		justify-content:flex-start;
		max-width:100%;
		margin:0;
		padding-left:29px;
		align-items:center;
		z-index:auto;
		right:unset;
		left:0;

		li{
			border:1px solid #FFF;
			background-color:#FFF;
			border-radius:100%;
			font-size:9px;
			width:1em;
			opacity:1;
			transition:all 0.6s linear;
			height:1em;
			@include margin(0,9);
			position:relative;
			z-index:400;

			&.active{
				background-color:transparent;
				width:(13 / 11) * 1em;
				height:(13 / 11) * 1em;
			}

		}

	}

}

.header-section{
	position:relative;

	.header-section__title{
		font-family: 'montserratextrabold';
		font-size:1em;
		line-height:0.76;
		margin-bottom:0;
	}

}

.section-produtos{
	background-color:#000;
	color:#FFF;
	padding-top:86px;
	padding-bottom:46px;
	position:relative;
	z-index:0;

	@include beforeAfter{
		content:'';
		display:block;
		position:absolute;
		background-repeat:no-repeat;
		z-index:-2;

		@include media-breakpoint-down(md){
			display:none;
		}

	}

	&:before{
		width:746px;
		height:850px;
		top:0;
		left:0;
		background-image:url('../images/bg-before-produto.png');
	}

	&:after{
		width:654px;
		height:745px;
		top:50%;
		transform:translateY(-50%);
		right:0;
		background-image:url('../images/bg-after-produto.png');	
	}

	.header-section{
		font-size:200px;
		text-align:right;
		max-width:586px;
		margin-bottom:112px;

		@include media-breakpoint-down(md){
			font-size:80px;
		}

		img{

			@include media-breakpoint-down(md){
				@include img-fluid;
			}
			
			@include media-breakpoint-up(lg){
				position:absolute;
				top:-80px;
				left:calc(100% - 84px);
				pointer-events:none;
			}

		}

	}

}

.section-siga-nos{
	padding-top:66px;
	background-color:#FFF;

	.header-section{
		font-size:160px;
		color:#000;
		margin-bottom:40px;
		text-align:center;

		@include media-breakpoint-down(md){
			font-size:40px;
		}

	}

	.redes-sociais{
		margin-bottom:46px;

		@include media-breakpoint-up(md){
			display:flex;
			justify-content:center;
			align-items:center;
		}

		& > *{
			font-size:20px;
			font-family: 'montserratbold';
			@include margin(0,24);
			color:#000;

			@include media-breakpoint-down(md){
				display:block;
				margin:0;
				text-align:center;
				margin-bottom:20px;
			}

		}

	}

}