.rodape{

	&.rodape--index{
		color:#FFF;
		padding-top:84px;
		padding-bottom:46px;
		text-align:right;
		background-size:cover;
		background-repeat:no-repeat;

		.rodape-index__title{
			font-size:100px;
			font-family: 'montserratextrabold';
			line-height:0.8;
			margin-bottom:16px;

			@include media-breakpoint-down(md){
				font-size:70px;
			}

		}

		.rodape-index__desc{
			max-width:484px;
			margin-bottom:28px;
			margin-left:auto;
		}

		.rodape-index__empresa{
			font-size:15px;
			font-family: 'montserratbold';
			line-height:1.1;

			@include media-breakpoint-down(md){
				margin-bottom:20px;
			}

			@include media-breakpoint-up(lg){
				margin-right:8px;
				padding-top:12px;
			}

		}

	}

	&.rodape--internas{
		color:#FFF;
		background-color:#000;

		@include media-breakpoint-down(md){
			text-align:center;
		}

		.rodape-internas__info{
			background-repeat:no-repeat;
			background-size:cover;
			background-position:center center;
			padding-top:48px;
			padding-bottom:20px;

			.rodape-info__title{
				font-size:15px;
				margin-bottom:24px;
				font-family: 'montserratbold';
				line-height:1;
			}

			.rodape-info__menu{
				list-style-type:none;
				margin-bottom:40px;
				padding-left:0;

				li{
					margin-bottom:12px;
				}

			}

			.rodape-info__forma-pagamento{
				font-size:12px;
				font-family: 'montserratblack';
				line-height:1.2;

				.row{

					& > *{

						@include media-breakpoint-down(md){
							margin-bottom:15px;
						}

					}

				}

			}

			.media-custom{
				align-items:center;
				margin-bottom:18px;

				.media-custom__img{

					@include media-breakpoint-up(md){
						margin-right:10px;
					}

				}

				.media-custom__text{
					line-height:1;
				}

			}

			& > .container{

				& > .row > *{
					margin-bottom:37px;
				}

			}

		}

		.rodape-internas__copyright{
			background-color:#343434;
			padding-top:28px;
			padding-bottom:22px;
			text-align:center;
			font-size:12px;

			span{
				font-family: 'montserratbold';	
			}

			.img--gv8{
				max-width:43px;
				margin-left:6px;
				transform:translateY(1px);
				vertical-align:text-bottom;
			}

		}

	}

}