.media-custom{

	@include media-breakpoint-up(sm){
		display:flex;
		align-items:flex-end;
	}

	.media-custom__img{
		line-height:1;
	}

}